import React, { useContext } from 'react';
import { AppContext } from '../../context';
import { SECTIONS } from '../../constants';
import { Wrapper } from './configurator.styles';
import { Button } from 'antd';

import AddItem from '../AddItem';
import ConfigureItem from '../ConfigureItem';

const Configurator = () => {
  const { section, handleSetSection } = useContext(AppContext);
  return (
    <Wrapper>
      {section === SECTIONS.addItem && <AddItem />}
      {section === SECTIONS.configureItem && <ConfigureItem />}

      {Object.values(SECTIONS).map((section, i) => (
        <Button key={i} onClick={() => handleSetSection(section)}>
          {section}
        </Button>
      ))}
    </Wrapper>
  );
};

export default Configurator;
