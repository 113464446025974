import React from 'react';
import { Wrapper } from './app.styles';

//  components
import Player from './Components/Player';
import Configurator from './Components/Configurator';

function App() {
  return (
    <Wrapper>
      <Player />
      <Configurator />
    </Wrapper>
  );
}

export default App;
