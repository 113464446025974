import React, { useState, useContext } from 'react';
import { Select, InputNumber, Button } from 'antd';
import { Wrapper } from './configureItem.styles';
import { AppContext } from '../../context';
import { camelToSpaces } from '../../utils';

const Item = ({ label }) => {
  return (
    <div>
      {label} <Select placeholder="Select a fabric"></Select>
    </div>
  );
};

const Section = ({ options }) => {
  if (Array.isArray(options)) {
    return options.map((label, i) => <Item label={label} key={i} />);
  }

  return (
    <div>
      {options &&
        Object.entries(options).map(([key, val], i) => (
          <div key={i}>
            <h4>{key}</h4>
            {Array.isArray(val) ? (
              val.map((el, j) => <Item label={el} key={j} />)
            ) : (
              <Section options={val} />
            )}
          </div>
        ))}
    </div>
  );
};

const ConfigureItem = () => {
  const {
    selected,
    data,
    handleRotate,
    handleGetItem,
    handleDeleteItems,
  } = useContext(AppContext);
  const [deg, setDeg] = useState(null);
  const item = handleGetItem(selected);
  if (!item) return <div></div>;
  const { configurationOpts } = data.items[item.type];

  const handleClick = () => handleRotate(deg);

  const handleClickDelete = () => handleDeleteItems([item.id]);

  return (
    <Wrapper>
      <div>{camelToSpaces(item.type)}</div>
      <div>
        <InputNumber min={1} max={360} value={deg} onChange={setDeg} />
        <Button type="primary" onClick={handleClick}>
          Rotate
        </Button>
      </div>
      <div>
        <Button type="danger" onClick={handleClickDelete}>
          Delete Item
        </Button>
      </div>
      <div>
        <Section options={configurationOpts} />
      </div>
    </Wrapper>
  );
};

export default ConfigureItem;
