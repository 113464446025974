import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;

  #player-root {
    width: calc(100vw - 380px);
    height: 100vh;
  }
`;

// BlueAcorn's desktop player resolution
// export const Wrapper = styled.div`
//   height: 464px;
//   width: 880px;

//   #player-root {
//     height: 464px;
//     width: 880px;
//   }
// `;
