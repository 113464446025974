import React, { useState, useContext } from 'react';
import { Button, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Wrapper, Item } from './addItem.styles.js';
import { AppContext } from '../../context.js';
import { camelToSpaces } from '../../utils';

const AddItem = () => {
  const [expanded, setExpanded] = useState(new Set([]));
  const { data, handleAddItem, handleSetFloor } = useContext(AppContext);

  const handleExpand = (idx) => {
    const updated = new Set(expanded);
    expanded.has(idx) ? updated.delete(idx) : updated.add(idx);
    setExpanded(updated);
  };

  const handleClick = (type, key) => handleAddItem(type, key);

  return (
    <Wrapper>
      <h2>Room Configurator</h2>
      <div>
        <Select
          onChange={handleSetFloor}
          placeholder="Select a floor type"
          style={{ width: '160px' }}
        >
          {data.floorTypes.map((type, i) => (
            <Select.Option key={i} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </div>
      <h2>Add an Item</h2>
      {Object.values(data.items).map((item, i) => (
        <Item key={i} selected={expanded.has(i)}>
          <div onClick={() => handleExpand(i)}>
            <div>{camelToSpaces(item.type)}</div>
            <div>
              <DownOutlined />
            </div>
          </div>
          <div>
            <div>
              {item.keys.map((opt, j) => (
                <Button
                  key={j}
                  type="primary"
                  value={opt}
                  style={{ marginRight: '8px', marginBottom: '8px' }}
                  onClick={() => handleClick(item.type, opt)}
                >
                  {camelToSpaces(opt)}
                </Button>
              ))}
            </div>
          </div>
        </Item>
      ))}
    </Wrapper>
  );
};

export default AddItem;
