import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Item = styled.div`
  max-height: ${(props) => (props.selected ? '500px' : '50px')};
  /* width: 200px; */
  border: 1px solid #dddddd;
  border-radius: 4px;
  overflow: hidden;
  padding: 0 12px;
  margin-bottom: 8px;

  transition: all 0.3s;

  & > div:nth-child(1) {
    height: 50px;

    display: grid;
    grid-template-columns: auto max-content;

    & > div {
      height: max-content;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    & > div:nth-child(2) {
      transition: all 0.3s;
      transform: translateY(-50%)
        rotate(${(props) => (props.selected ? '180deg' : '0deg')});
    }
  }

  & > div:nth-child(2) {
    height: max-content;

    & > div {
      padding: 15px 0;
    }
  }
`;
