import React, { useEffect } from 'react';
import { Wrapper } from './player.styles';

const Player = () => {
  // Not sure why this init is also here. The configurator already inits in
  // AppProvider's componentDidMount (context.js)
  // useEffect(() => {
  //   (() => {
  //     console.log(window.threekit);
  //     window.threekit.init({ el: 'player-root' });
  //   })();
  // });

  return (
    <Wrapper>
      <div id="player-root" />
    </Wrapper>
  );
};

export default Player;
