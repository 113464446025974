import React from 'react';
import { SECTIONS } from './constants';
import data from './data.json';

export const AppContext = React.createContext(null);

// immediately switching to config screen after adding an item has proven to be
// mostly a nuisance in testing, since you want to first add a bunch of
// different items simultaneously. This counter is used to only switch to the
// configure panel if there is a new selection and no pending items being added
// - ie the selection is almost certainly due to a user selection, not an
// addItem call.
let pendingItems = 0;

class AppProvider extends React.Component {
  handleSetSection = (section) => this.setState({ section });

  handleAddItem = async (type, key) => {
    pendingItems++;
    await window.threekit.configurator.addItem({ type, key });
    pendingItems--;
  };

  handleItemSelection = (idxs) => {
    const firstSelectedItemId =
      idxs === undefined || idxs.length === 0 ? null : idxs[0];
    this.setState({
      selected: firstSelectedItemId,
      section:
        firstSelectedItemId && !pendingItems
          ? SECTIONS.configureItem
          : SECTIONS.addItem,
    });
  };

  handleGetItem = (idx = this.state.selected) => {
    const item = idx && window.threekit.configurator.getItems().get(idx);
    if (item) return { ...item, id: idx };
    else return null;
  };

  handleDeleteItems = (idxs) => {
    if (!idxs || !idxs.length) return;
    window.threekit.configurator.removeItems(idxs);
    this.setState({ section: SECTIONS.addItem });
  };

  handleSetFloor = (val) => window.threekit.configurator.setFloor(val);

  handleRotate = (deg) =>
    window.threekit.configurator.rotateItems([this.state.selected], deg);

  state = {
    data,
    selected: null,
    section: SECTIONS.addItem,
    handleSetSection: this.handleSetSection,
    handleAddItem: this.handleAddItem,
    handleSetFloor: this.handleSetFloor,
    handleRotate: this.handleRotate,
    handleGetItem: this.handleGetItem,
    handleDeleteItems: this.handleDeleteItems,
  };

  componentDidMount() {
    const tenSeats_twelvesSides_preview =
      '83670f03-863b-41ee-b730-68d2e193d2c3';

    const tenSeats_twelvesSides_admin = '77fdcb85-3424-43a9-ab1f-a539f93445c9';

    window.threekit
      .init({
        el: 'player-root',
        onSelectionChange: this.handleItemSelection,
        // configurationId: tenSeats_twelvesSides_preview,
        // initialConfiguration: {
        //   items: [{ type: 'seat', key: 'standard' }],
        //   floor: 'Espresso',
        // },
        // configurationId: '55709ed2-c3d4-4184-8035-4ecb15255be9',
      })
      .then(() => {
        //expose these directly on window for dev convenience
        window.api = window.threekit.api;
        window.configurator = window.threekit.configurator;
      });
  }

  componentDidUpdate() {
    process.env.NODE_ENV === 'development' && console.log(this.state);
  }

  render() {
    return (
      <AppContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppProvider;
